import styled from "styled-components"
import ReactMarkdown from "react-markdown"

export const Banner = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #f7f7f7;

  position: relative;
`
export const Inner = styled.div`
  width: 100%;
  max-width: 1280px;

  z-index: 11;

  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  margin: ${props => `70px ${props.theme.boxLayout.margin.value} 0`};
  box-sizing: border-box;

  @media (min-width: 512px) {
    min-height: 690px;
  }
`

export const Title = styled.p`
  display: none;
  font-weight: 500;

  @media (max-width: 768px) {
    display: inline-block;
    text-align: center;
    margin: 8px 0;
  }

  @media (max-width: 512px) {
    font-size: 1.1em;
  }

  @media (min-width: 513px) and (max-width: 860px) {
    font-size: 1.5em;
    margin: 10px 0;
  }
`

export const SignUpLoginText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  font-size: 1.6rem;
  line-height: 2.6rem;
  margin: 25px 0;

  span {
    color: #ababab;
    font-style: italic;
    font-weight: 500;
  }

  a {
    font-style: normal;
    color: ${props => props.theme.colours.tertiary};
    text-decoration: none;
  }

  @media (max-width: 512px) {
    span:first-child {
      margin-bottom:10px
    }
`

export const OfferList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const Markdown = styled(ReactMarkdown)`
  margin-bottom: 25px;
`
